import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LottieAnimationViewModule } from 'ng-lottie';

import { AppComponent } from './app.component';
import { LogoComponent } from './logo/logo.component';
import { ScreenshotsComponent } from './screenshots/screenshots.component';
import { TrailerComponent } from './trailer/trailer.component';
import { SocialComponent } from './social/social.component';
import { PlatformsComponent } from './platforms/platforms.component';

@NgModule({
  declarations: [
    AppComponent,
    LogoComponent,
    ScreenshotsComponent,
    TrailerComponent,
    SocialComponent,
    PlatformsComponent
  ],
  imports: [
    BrowserModule,
    LottieAnimationViewModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
