import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-trailer',
  templateUrl: './trailer.component.html',
  styleUrls: ['./trailer.component.css']
})
export class TrailerComponent implements OnInit {

  @ViewChild('trailer') trailer: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  onResize(event)
  {
    var height = this.trailer.nativeElement.clientWidth / 16 * 9;
    this.trailer.nativeElement.setAttribute('style', 'height: ' + height + 'px');
  }

}
